



























































import { Vue, Component, Mixins, Ref } from 'vue-property-decorator';

import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { CategoryGroup } from '@/models';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ConfirmModal from '@/components/ConfirmModal.vue';
import StoreFrontSectionsMixin, { StoreFrontSectionsMixinTags } from '@/mixins/http/StoreFrontSectionsMixin';
import StoreFrontSection from '@/models/StoreFrontSection';
import WithErrors from '@/directives/WithErrors';
import StoreFrontSectionCreateModal from '@/components/StoreFront/Section/CreateModal.vue';

@Component({
  components: {
    DataTable,
    ConfirmModal,
    StoreFrontSectionCreateModal
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontContentSectionList extends Mixins(StoreFrontSectionsMixin) {
  private sections: StoreFrontSection[] = [];

  @RegisterHttp(StoreFrontSectionsMixinTags.Index) readonly indexRequest!: RequestData;

  @Ref() readonly confirmModal!: ConfirmModal;
  @Ref() readonly sectionCreateModal!: StoreFrontSectionCreateModal;

  created() {
    this.loadSections();
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get columns() {
    return [
      { name: "title", label: "Nome" },
      { name: "subtitle", label: "Sottotitolo" },
      { name: "pageCode", label: "Codice pagina" },
      { name: "actions", label: "" }
    ];
  }

  get payload() {
    return {
      filter: {
        storeFront: this.storeFrontId
      }
    };
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  private async loadSections() {
    const [data, errors] = await easync(
      this.getStoreFrontSections(this.payload, StoreFrontSectionsMixinTags.Index)
    );

    if (data) {
      this.sections = data;
    } else {
      this.sections = [];
      showErrorToast('Si è verificato un problema');
    }
  }

  private onSelected(value: StoreFrontSection) {
    this.goToStoreFrontSectionShow(value.id);
  }

  private goToStoreFrontSectionShow(id: string) {
    this.$router.push({
      name: 'store_front_sections_show',
      params: { id: this.storeFrontId, sectionId: id }
    });
  }

  private async openConfirmModal(value: CategoryGroup) {
    const message = "Sei sicuro di voler eliminare questa Sezione?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(value.id);
    }
  }

  private async onDelete(id: string) {
    const [data, errors] = await easync(this.destoryStoreFrontSection(id, StoreFrontSectionsMixinTags.Destroy + id));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la cancellazione');
      return;
    }

    showToast('Sezione eliminata con successso!');
    this.loadSections();
  }

  private onCreateNewSection() {
    this.sectionCreateModal.show(this.storeFrontId);
  }
}
