

















import { Component, Ref, Mixins, Prop } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';
import { BannerGroupPayload } from '@/models/BannerGroup';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import { StoreFrontSectionPayload } from '@/models/StoreFrontSection';
import StoreFrontSectionsMixin, { StoreFrontSectionsMixinTags } from '@/mixins/http/StoreFrontSectionsMixin';
import StoreFrontSectionCreateForm from '@/components/StoreFront/Section/CreateForm.vue';

@Component({
  components: {
    VModal,
    StoreFrontSectionCreateForm
  }
})
export default class BannerGroupCreateModal extends Mixins(StoreFrontSectionsMixin) {
  private storeFrontId = '';

  @Ref() readonly modal!: VModal;

  private sectionPayload: StoreFrontSectionPayload = { title: '', pageCode: '', storeFrontId: '' };

  show(storeFrontId: string) {
    this.storeFrontId = storeFrontId;
    this.modal.show();
  }

  hide() {
    this.reset();
    this.modal.hide();
  }

  private onCancel() {
    this.hide();
  }

  private reset() {
    this.sectionPayload.title = '';
    this.sectionPayload.pageCode = '';
    this.sectionPayload.storeFrontId = '';
  }

  private async onConfirm() {
    this.sectionPayload.storeFrontId = this.storeFrontId;
    const [data, errors] = await easync(
      this.createStoreFrontSection(this.sectionPayload, StoreFrontSectionsMixinTags.Create)
    );

    if (errors) {
      showErrorToast('Si è verificato un errore durante la creazione della Sezione');
      return;
    }

    showToast('Sezione creata!');
    this.$emit('created', data);
    this.hide();
  }
}
