































import { Vue, Component, Prop } from 'vue-property-decorator';

import WithErrors from '@/directives/WithErrors';
import { BannerGroupsMixinTags } from '@/mixins/http/BannerGroupsMixin';
import { StoreFrontSectionPayload } from '@/models/StoreFrontSection';
import StoreFrontSectionPageCodeSelect from '@/components/StoreFront/Section/PageCodeSelect.vue';
import { StoreFrontSectionsMixinTags } from '@/mixins/http/StoreFrontSectionsMixin';

@Component({
  components: {
    StoreFrontSectionPageCodeSelect
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontSectionCreateForm extends Vue {
  @Prop({ required: true }) readonly value!: StoreFrontSectionPayload;

  private payload: StoreFrontSectionPayload = { title: '', pageCode: '', storeFrontId: '' };

  created() {
    if (this.value) {
      this.payload = {...this.value};
    }
  }

  get createTag() {
    return StoreFrontSectionsMixinTags.Create;
  }

  private onPageCodeSelected(pageCodeId: string) {
    this.payload.pageCode = pageCodeId;
    this.emitPayload();
  }

  private emitPayload() {
    this.$emit('input', this.payload);
  }
}
